@tailwind base;
@tailwind components;
@tailwind utilities;
@import './variables.scss';

*:focus {
  outline-color: #1B953E;
}

body,
html {
  font-family: 'Inter', 'Noto Sans TC', sans-serif;
  min-height: calc(100dvh - $header-height);
  background-color: #F8F8FB;
}

html > * {
  &:focus {
    outline-color: #1B953E;
  }
  &:focus-visible {
    outline-color: #1B953E;
  }
}

.one-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

.two-line-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* word-break: break-all;
    fallback if the browser doesnt support the code below */
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    /* word-break: break-all; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.three-line-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* word-break: break-all;
    fallback if the browser doesnt support the code below */
  @supports (-webkit-line-clamp: 3) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    /* word-break: break-all; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.user-drag-none {
  /* user-drag: none; */
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.one-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

.two-line-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* word-break: break-all;
    fallback if the browser doesnt support the code below */
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    /* word-break: break-all; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.three-line-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* word-break: break-all;
    fallback if the browser doesnt support the code below */
  @supports (-webkit-line-clamp: 3) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    /* word-break: break-all; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.user-drag-none {
  /* user-drag: none; */
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
